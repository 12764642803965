/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Link,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { useDispatch } from 'react-redux';
import { article, ExtCitationItemDto } from '@ink-ai/portal/reducers/article';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

interface CitationCardProps {
  isPreview?: boolean;
  citation: ExtCitationItemDto;
}
const maxLength = 200;

const CitationCard: React.FC<CitationCardProps> = ({ isPreview, citation }) => {
  const { t } = useTranslation();
  const truncatedText =
    citation.text.length > maxLength
      ? `${citation.text.substring(0, maxLength)}...`
      : citation.text;

  let domain: string;
  try {
    if (citation.connector.type !== 'file') {
      domain = new URL(citation.source).hostname;
    }
  } catch (error) {
    console.error('Invalid URL:', citation.source);
    domain = '';
  }

  const dispatch = useDispatch<any>();

  return (
    <Card
      id={`plugin-card-${citation.id}`}
      sx={{
        marginBottom: 2,
        borderRadius: 4,
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {isPreview ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={citation.checked}
                  onChange={(e) => {
                    dispatch(
                      article.actions.changePreviewCitationChecked({
                        id: citation.id,
                        checked: e.target.checked,
                      }),
                    );
                  }}
                />
              }
              label={citation.title}
            />
          ) : (
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', textAlign: 'left', flexGrow: 1 }}
            >
              {citation.title}
            </Typography>
          )}
          {domain ? (
            <Tooltip title={citation.connector.name} arrow>
              <Avatar
                src={`https://api.faviconkit.com/${domain}`}
                sx={{ marginLeft: 2, width: 24, height: 24 }}
              />
            </Tooltip>
          ) : (
            <Tooltip title={citation.connector.name} arrow>
              <Avatar
                sx={{
                  marginLeft: 2,
                  width: 24,
                  height: 24,
                }}
              >
                <StorageIcon sx={{ fontSize: 16 }} />
              </Avatar>
            </Tooltip>
          )}
        </Box>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            textAlign: 'left',
            mt: 2,
          }}
        >
          {truncatedText}
          <Tooltip
            sx={{ mt: 1 }}
            title={
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {citation.text}
              </ReactMarkdown>
            }
            arrow
          >
            <Link sx={{ cursor: 'pointer' }}>{t('citation.readMore')}</Link>
          </Tooltip>
        </Typography>
        <Typography
          variant="body2"
          color="primary"
          sx={{
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            mt: 2,
          }}
        >
          {citation?.connector?.type !== 'file' ? (
            <Link href={citation.source} target="_blank" underline="hover">
              {citation.id} - {citation.source}
            </Link>
          ) : (
            `${citation.id} - ${citation.source}`
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CitationCard;
