/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { RootState } from '@ink-ai/portal/reducers';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Box,
  Typography,
  IconButton,
  InputBase,
  Divider,
  LinearProgress,
} from '@mui/material';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import remarkGfm from 'remark-gfm';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  article,
  startInstructionContentGeneration,
} from '@ink-ai/portal/reducers/article';
import { CitationPage } from '../../Citation/CitationPage';
import { isOffice } from '@ink-ai/portal/common/utils';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const SectionPreview = () => {
  const articleState = useSelector((state: RootState) => state.article);
  const dispatch = useDispatch<any>();
  const previewRef = useRef<HTMLDivElement>(null);

  const [articleOpen, setArticleOpen] = useState(true);
  const [citationOpen, setCitationOpen] = useState(true);

  useEffect(() => {
    if (previewRef.current) {
      previewRef.current.scrollTop = previewRef.current.scrollHeight;
    }
  }, [articleState?.previewSectionText]);

  const getGridWidth = (
    isArticle: boolean,
    articleOpen: boolean,
    citationOpen: boolean,
  ) => {
    if (articleOpen && citationOpen) return 6;
    if (articleOpen || citationOpen) return isArticle === articleOpen ? 11 : 1;
    return 6;
  };

  const articleGridWidth = useMemo(
    () => getGridWidth(true, articleOpen, citationOpen),
    [articleOpen, citationOpen],
  );

  const citationGridWidth = useMemo(
    () => getGridWidth(false, articleOpen, citationOpen),
    [articleOpen, citationOpen],
  );

  return (
    <div>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>Preview</Box>
        <IconButton
          onClick={() => {
            if (articleState.isGenerating) {
              return;
            }
            dispatch(
              article.actions.showSectionDialog({
                show: false,
              }),
            );
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={articleGridWidth} height="75vh">
            <Box
              ref={previewRef}
              sx={{
                border: articleOpen ? '1px solid #999' : 'none',
                height: '70vh',
                maxHeight: '80vh',
                overflow: 'auto',
                padding: 1,
              }}
            >
              {articleOpen && (
                <>
                  {articleState?.previewSectionText ? (
                    <>
                      {articleState.showPreviewHtml ? (
                        <div
                          role="none"
                          onClick={(e) => {
                            const target = e.target as HTMLElement; // 明确目标元素类型
                            const citationId =
                              target?.getAttribute('data-citation-id');
                            if (citationId) {
                              dispatch(
                                article.actions.setCurrentCitationId(
                                  parseInt(citationId, 10),
                                ),
                              );
                            }
                          }}
                          dangerouslySetInnerHTML={{
                            __html: articleState.previewSectionText,
                          }}
                        ></div>
                      ) : (
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {articleState?.previewSectionText?.replace(
                            /~/g,
                            '\\~',
                          )}
                        </ReactMarkdown>
                      )}
                    </>
                  ) : (
                    articleState?.isGenerating && (
                      <Box sx={{ mt: 1, width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    )
                  )}
                </>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent={articleOpen ? 'flex-end' : 'flex-start'}
            >
              {articleOpen ? (
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setArticleOpen(false);
                  }}
                >
                  <ArrowLeftIcon fontSize="large" />
                </Button>
              ) : (
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setArticleOpen(true);
                  }}
                >
                  <ArrowRightIcon fontSize="large" />
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={citationGridWidth} height="75vh">
            <Box
              sx={{
                p: 1,
                border: citationOpen ? '1px solid #999' : 'none',
                height: '70vh',
                maxHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {citationOpen && (
                <>
                  <Box sx={{ paddingBottom: '5px' }}>
                    <Typography variant="h6">
                      Sources ({articleState.previewCitations?.length ?? 0})
                    </Typography>
                    <Typography variant="body2" color="#666">
                      Uncheck the source to regenerate.
                    </Typography>
                    <Divider />
                    <Box
                      component="form"
                      sx={{
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        border: '1px solid #999',
                        borderRadius: '4px',
                      }}
                    >
                      <IconButton type="button" aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <InputBase
                        placeholder="Search the source"
                        value={articleState.previewCitationFilterText}
                        onChange={(e) => {
                          dispatch(
                            article.actions.changePreviewCitationFilterText(
                              e.target.value,
                            ),
                          );
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ flex: 1, overflow: 'auto' }}>
                    <CitationPage isPreview />
                  </Box>
                </>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent={citationOpen ? 'flex-start' : 'flex-end'}
            >
              {citationOpen ? (
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setCitationOpen(false);
                  }}
                >
                  <ArrowRightIcon fontSize="large" />
                </Button>
              ) : (
                <Button
                  sx={{ p: 0 }}
                  onClick={() => {
                    setCitationOpen(true);
                  }}
                >
                  <ArrowLeftIcon fontSize="large" />
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: '0 15px 15px 0' }}>
        <Button
          disabled={articleState.isGenerating}
          variant="outlined"
          onClick={() => {
            dispatch(startInstructionContentGeneration({ isRegenerate: true }));
            if (isOffice()) {
              Office.context.ui?.messageParent('regenerate');
            }
          }}
        >
          Regenerate
        </Button>
        <Button
          disabled={articleState.isGenerating}
          variant="contained"
          onClick={() => {
            if (isOffice()) {
              Office.context.ui?.messageParent('accept');
            } else {
              dispatch(article.actions.insertSectionToDocument());
            }
          }}
          autoFocus
        >
          Insert
        </Button>
      </DialogActions>
    </div>
  );
};

export default SectionPreview;
